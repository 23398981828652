import { Injectable } from '@angular/core';
import { WsACKCallback } from '@common/api/ack';
import { IID_FAULT_MANAGEMENT } from '@common/interfaces/interfaceData';
import { Topics } from '@common/interfaces/topics';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { ProjectEntityStoreStrategy } from '../updateStoreStrategies';
import { IIDFaultManagementQuery } from './iidFaultManagement.query';
import { IIDFaultManagementStore } from './iidFaultManagement.store';

@Injectable({
  providedIn: 'root'
})
export class IIDFaultManagementService extends CrudService<IID_FAULT_MANAGEMENT, IIDFaultManagementStore, IIDFaultManagementQuery> {

  constructor(
    protected store: IIDFaultManagementStore,
    public query: IIDFaultManagementQuery,
    protected actionService: ActionService,
    protected updateStrategy: ProjectEntityStoreStrategy,
  ) {
    super(
      Topics.IID_FAULT_MANAGEMENT,
      store,
      query,
      actionService,
      updateStrategy,
    );
  }

  tryAgainSendFaultManagementToGisfo(id: string, callback?: WsACKCallback<void>) {
    this.actionService.sendMessage(Topics.IID_FAULT_MANAGEMENT, 'tryAgainSendFaultManagementToGisfo', { id }, callback);
  }

  createTaskOnGuastiNID(sourceIssueId: string, projectDestinationId: string, issueTypeDestinationId: string, callback?: WsACKCallback<void>) {
    this.actionService.sendMessage(Topics.IID_FAULT_MANAGEMENT, 'createTaskOnGuastiNID', { sourceIssueId, projectDestinationId, issueTypeDestinationId }, callback);
  }
}
