import { Injectable } from "@angular/core";
import { ID } from "@common/interfaces/id";
import { IIssue } from "@common/interfaces/issue";
import { IIssueState } from "@common/interfaces/issueState";
import { IIssueType } from "@common/interfaces/issueType";
import { InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { WorkflowDog } from "@common/utils/workflowDog";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthQuery } from "../auth/auth.query";
import { IssueContextQuery } from "./issueContextQuery";
import { IssueStateQuery } from "./issueState/issueState.query";
import { IssueStateWorkflowQuery } from "./issueStateWorkflow/issueStateWorkflow.query";
import { IssueTypeQuery } from "./issueType/issueType.query";
import { IssueTypeInterfaceQuery } from "./issueTypeInterface/issueTypeInterface.query";
import { IssueTypeWorkflowQuery } from "./issueTypeWorkflow/issueTypeWorkflow.query";
import { ProjectQuery } from "./project/project.query";
import { ProjectScopeQuery } from "./projectScope/projectScope.query";
import { UserQuery } from "./user/user.query";
import { WorkflowQuery } from "./workflow/workflow.query";
import { combineQueries } from "@datorama/akita";
import { IWorkflow } from "@common/interfaces/workflow";
import { FormlySelectOption } from "@common/interfaces/formly";
import { SettingsService } from "../pages/settings/settings.service";

@Injectable({
  providedIn: 'root'
})
export class WorkflowDependentQuery {
  constructor(
    private workflowQuery: WorkflowQuery,
    private issueContextQuery: IssueContextQuery,
    private issueTypeWorkflowQuery: IssueTypeWorkflowQuery,
    private issueTypeQuery: IssueTypeQuery,
    private issueStateQuery: IssueStateQuery,
    private projectQuery: ProjectQuery,
    private authQuery: AuthQuery,
    private issueStateWorkflowQuery: IssueStateWorkflowQuery,
    private issueTypeInterfaceQuery: IssueTypeInterfaceQuery,
    private userQuery: UserQuery,
    private projectScopeQuery: ProjectScopeQuery,
    private settingService: SettingsService,
  ) { }

  selectAllowedTypesValidForCreationByWorkflow$(workflowId: ID): Observable<IIssueType[]> {
    return combineLatest([this.issueTypeQuery.all$, this.issueTypeWorkflowQuery.selectValidForCreationByWorkflow$(workflowId)]).pipe(
      map(([types, allowed]) => allowed.map(entity => types.find(type => type.id === entity.issueTypeId)))
    )
  }

  getAllowedTypesValidForCreationByWorkflow(workflowId: ID): IIssueType[] {
    const validIssueType = this.issueTypeWorkflowQuery.getValidForCreationByWorkflow(workflowId);
    return validIssueType.map(allowed => this.issueTypeQuery.getEntity(allowed.issueTypeId));
  }

  selectAllowedTypesByWorkflow$(workflowId: ID): Observable<IIssueType[]> {
    return combineLatest([this.issueTypeQuery.all$, this.issueTypeWorkflowQuery.selectByWorkflow$(workflowId)]).pipe(
      map(([types, allowed]) => allowed.map(entity => types.find(type => type.id === entity.issueTypeId)))
    )
  }

  selectAllowedStatesByWorkflow$(workflowId: ID): Observable<IIssueState[]> {
    return combineLatest([this.issueStateQuery.all$, this.issueStateWorkflowQuery.selectByWorkflow$(workflowId)]).pipe(
      map(([states, allowed]) => allowed.map(entity => states.find(state => state.id === entity.issueStateId)))
    )
  }

  getWorkflowsWithIssueType(issueTypeId: ID): ID[] {
    return [...new Set(this.issueTypeWorkflowQuery.getByIssueType(issueTypeId).map(c => c.workflowId))];
  }

  getAllowedTypesByWorkflow(id: ID): IIssueType[] {
    const issueTypes = this.issueTypeQuery.getAll();
    const allowedTypes = this.issueTypeWorkflowQuery.getAll({ filterBy: item => item.workflowId === id });
    return allowedTypes.map(allowed => issueTypes.find(issueType => issueType.id === allowed.issueTypeId));
  }

  getAllowedStatesByWorkflow(id: ID): IIssueState[] {
    const issueStates = this.issueStateQuery.getAll();
    const allowedStates = this.issueStateWorkflowQuery.getAll({ filterBy: item => item.workflowId === id });
    return allowedStates.map(allowed => issueStates.find(issueState => issueState.id === allowed.issueStateId));
  }

  getInterfaceNameByWorkflow(id: ID): InterfaceNameValue[] {
    const allowedTypes = this.issueTypeWorkflowQuery.getAll({ filterBy: item => item.workflowId === id });
    const interfaceNames = this.issueTypeInterfaceQuery.getAll().filter(iti => allowedTypes.map(at => at.issueTypeId).includes(iti.issueTypeId)).map(i => i.interfaceName);
    return interfaceNames.filter((value, index, arr) => arr.indexOf(value) === index);
  }

  selectInterfaceNameByWorkflow$(id: ID): Observable<InterfaceNameValue[]> {
    return combineLatest([
      this.issueTypeWorkflowQuery.selectAll({ filterBy: item => item.workflowId === id }),
      this.issueTypeInterfaceQuery.selectAll()
    ]).pipe(map(([allowedTypes, issueTypeInterface]) => {
      return issueTypeInterface.filter(iti => allowedTypes.map(at => at.issueTypeId).includes(iti.issueTypeId)).map(i => i.interfaceName);
    }))
  }

  selectInterfaceNameFormOptions(workflow$: Observable<IWorkflow>): Observable<FormlySelectOption[]> {
    return workflow$.pipe(
      switchMap((workflow) => this.selectInterfaceNameByWorkflow$(workflow.id).pipe(
        map((interfaceName) => this.settingService.interfacesToOptions(interfaceName)))
      ),
    );
  }


  selectAllowedStatesByIssue(issue: IIssue): Observable<IIssueState[]> {
    const issueContext$ = this.issueContextQuery.selectIssueContext$(issue.id);
    const wfId = this.projectQuery.getEntity(issue.projectId)?.workflowId;
    const projectScope = this.projectScopeQuery.getAll().find(s => s.projectId === issue.projectId);
    return combineQueries([
      this.issueStateQuery.selectByWorkflow$(issue.workflowId),
      issueContext$,
      this.workflowQuery.selectWorkflowSettingsByWorkflowId$(wfId),
      this.authQuery.userId$
    ]).pipe(
      map(
        ([issueStates, context, workflowSettings, userId]) => {
          return issueStates.filter(
            issueState => WorkflowDog.canChangeState(workflowSettings, context, issueState.id, this.userQuery.getUserWithRoles(userId, issue.projectId), projectScope)
          );
        })
    )
  }
}
