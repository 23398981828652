import { ProviderToken } from "@angular/core";
import { EntityState, EntityStore } from "@datorama/akita";

import { IBaseInterfaceData } from "@common/interfaces/base";
import { InterfaceName, InterfaceNameValue } from "@common/interfaces/issueTypeInterface";
import { ID } from "@common/interfaces/id";

import { AbstractInterfaceDataQuery } from "./interfaceDataModel";
import { CrudService } from "./crudService";

/**
 * Store Imports
*/
import { IIDolClienteStore } from "./iidOlCliente/iidOlCliente.store";
import { IIDolContrattoStore } from "./iidOlContratto/iidOlContratto.store";
import { IIDolOloRequestStore } from "./iidOlOloRequest/iidOlOloRequest.store";
import { IIDolActivationRequestStore } from "./iidOlActivationRequest/iidOlActivationRequest.store";
import { IIDolRiferimentiStore } from "./iidOlRiferimenti/iidOlRiferimenti.store";
import { IIDolCondominioStore } from "./iidOlCondominio/iidOlCondominio.store";
import { IIDolApparatoStore } from "./iidOlApparato/iidOlApparato.store";
import { IIDepAssetsStore } from "./iidEPAssets/iidEPAssets.store";
import { IIDolServizioAggiuntivoStore } from "./iidOlServizioAggiuntivo/iidOlServizioAggiuntivo.store";
import { IIDolChecklistStore } from "./iidOlChecklist/iidOlChecklist.store";
import { IIDepGisfoSupportStore } from "./iidEPGisfoSupport/iidEPGisfoSupport.store";
/**
 * Query Imports
*/
import { IIDolClienteQuery } from '@ep-om/project/state/iidOlCliente/iidOlCliente.query';
import { IIDolContrattoQuery } from '@ep-om/project/state/iidOlContratto/iidOlContratto.query';
import { IIDepAssetsQuery } from '@ep-om/project/state/iidEPAssets/iidEPAssets.query';
import { IIDolRiferimentiQuery } from '@ep-om/project/state/iidOlRiferimenti/iidOlRiferimenti.query';
import { IIDolCondominioQuery } from '@ep-om/project/state/iidOlCondominio/iidOlCondominio.query';
import { IIDolOloRequestQuery } from '@ep-om/project/state/iidOlOloRequest/iidOlOloRequest.query';
import { IIDolActivationRequestQuery } from '@ep-om/project/state/iidOlActivationRequest/iidOlActivationRequest.query';
import { IIDolServizioAggiuntivoQuery } from '@ep-om/project/state/iidOlServizioAggiuntivo/iidOlServizioAggiuntivo.query';
import { IIDolApparatoQuery } from '@ep-om/project/state/iidOlApparato/iidOlApparato.query';
import { IIDolChecklistQuery } from "./iidOlChecklist/iidOlChecklist.query";
import { IIDepGisfoSupportQuery } from "./iidEPGisfoSupport/iidEPGisfoSupport.query";
/**
 * Service Imports
*/
import { IIDolClienteService } from '@ep-om/project/state/iidOlCliente/iidOlCliente.service';
import { IIDolContrattoService } from '@ep-om/project/state/iidOlContratto/iidOlContratto.service';
import { IIDepAssetsService } from '@ep-om/project/state/iidEPAssets/iidEPAssets.service';
import { IIDolRiferimentiService } from '@ep-om/project/state/iidOlRiferimenti/iidOlRiferimenti.service';
import { IIDolCondominioService } from '@ep-om/project/state/iidOlCondominio/iidOlCondominio.service';
import { IIDolOloRequestService } from '@ep-om/project/state/iidOlOloRequest/iidOlOloRequest.service';
import { IIDolActivationRequestService } from '@ep-om/project/state/iidOlActivationRequest/iidOlActivationRequest.service';
import { IIDolServizioAggiuntivoService } from '@ep-om/project/state/iidOlServizioAggiuntivo/iidOlServizioAggiuntivo.service';
import { IIDolApparatoService } from '@ep-om/project/state/iidOlApparato/iidOlApparato.service';
import { IIDolChecklistService } from "./iidOlChecklist/iidOlChecklist.service";
import { IIDEpRichiestaStore } from "./iidEPRichiestaPermesso/iidEPRichiesta.store";
import { IIDEpRichiestaService } from "./iidEPRichiestaPermesso/iidEPRIchiesta.service";
import { IIDEpRichiestaQuery } from "./iidEPRichiestaPermesso/iidEPRichiesta.query";
import { IIDEpRispostaService } from "./iidEPRisposta/iidEPRisposta.service";
import { IIDEpRispostaQuery } from "./iidEPRisposta/iidEPRisposta.query";
import { IIDEpRispostaStore } from "./iidEPRisposta/iidEPRisposta.store";
import { IIDolAppuntamentoStore } from "./iidOlAppuntamento/iidOlAppuntamento.store";
import { IIDolAppuntamentoService } from "./iidOlAppuntamento/iidOlAppuntamento.service";
import { IIDolAppuntamentoQuery } from "./iidOlAppuntamento/iidOlAppuntamento.query";
import { IIDolAquisitiKoStore } from "./iidOlAquisitiKo/aquisitiKo.store";
import { IIDolAquisitiKoService } from "./iidOlAquisitiKo/aquisitiKo.service";
import { IIDolAquisitiKoQuery } from "./iidOlAquisitiKo/aquisitiKo.query";
import { IIDolAccettatoKoQuery } from "./iidOloAccettatoKo/accettatoKo.query";
import { IIDolAccettatoKoStore } from "./iidOloAccettatoKo/accettatoKo.store";
import { IIDolAccettatoKoService } from "./iidOloAccettatoKo/accettatoKo.service";
import { IIDolEspletatoOkService } from "./iidOlEspletatoOk/espletatoOk.service";
import { IIDolEspletatoOkStore } from "./iidOlEspletatoOk/espletatoOk.store";
import { IIDolEspletatoOkQuery } from "./iidOlEspletatoOk/espletatoOk.query";
import { IIDolEspletatoKoService } from "./iidOlEspletatoKo/espletatoKo.service";
import { IIDolEspletatoKoQuery } from "./iidOlEspletatoKo/espletatoKo.query";
import { IIDolEspletatoKoStore } from "./iidOlEspletatoKo/espletatoKo.store";
import { IIDolAnnullatoQuery } from "./iidOlAnnullato/annullato.query";
import { IIDolAnnullatoStore } from "./iidOlAnnullato/annullato.store";
import { IIDolAnnullatoService } from "./iidOlAnnullato/annulato.service";
import { IIDolCommunicationStore } from "./iidOlCommunication/iidOlCommunication.store";
import { IIDolCommunicationService } from "./iidOlCommunication/iidOlCommunication.service";
import { IIDolCommunicationQuery } from "./iidOlCommunication/iidOlCommunication.query";
import { IIDolSospensioneQuery } from "./iidOlSospensione/iidOlSospensione.query";
import { IIDolSospensioneStore } from "./iidOlSospensione/iidOlSospensione.store";
import { IIDolSospensioneService } from "./iidOlSospensione/iidOlSospensione.service";
import { IIDepTicketService } from "./iidEPTicket/iidEpTicket.service";
import { IIDepTicketStore } from "./iidEPTicket/iidEpTicket.store";
import { IIDepTicketQuery } from "./iidEPTicket/iidEpTicket.query";
import { IIDolRispostaSollecitoStore } from "./iidOlRispostaSollecito/iidOlRispostaSollecito.store";
import { IIDolRispostaSollecitoService } from "./iidOlRispostaSollecito/iidOlRispostaSollecito.service";
import { IIDolRispostaSollecitoQuery } from "./iidOlRispostaSollecito/iidOlRispostaSollecito.query";
import { IIDolSollecitoStore } from "./iidOlSollecito/iidOlSollecito.store";
import { IIDolSollecitoService } from "./iidOlSollecito/iidOlSollecito.service";
import { IIDolSollecitoQuery } from "./iidOlSollecito/iidOlSollecito.query";
import { IIDolSupportoQuery } from "./iidOlSupporto/iidOlSupporto.query";
import { IIDolSupportoStore } from "./iidOlSupporto/iidOlSupporto.store";
import { IIDolSupportoService } from "./iidOlSupporto/iidOlSupporto.service";
import { IIDolRisoluzioneQuery } from "./iidOlRisoluzione/iidOlRisoluzione.query";
import { IIDolRisoluzioneStore } from "./iidOlRisoluzione/iidOlRisoluzione.store";
import { IIDolRisoluzioneService } from "./iidOlRisoluzione/iidOlRisoluzione.service";
import { IIDgenericSuspensionQuery } from "./iidGenericSuspension/genericSuspension.query";
import { IIDgenericSuspensionStore } from "./iidGenericSuspension/genericSuspension.store";
import { IIDgenericSuspensionService } from "./iidGenericSuspension/genericSuspension.service";
import { IIDolRisorsaQuery } from "./iidOlRisorsa/iidOlRisorsa.query";
import { IIDolRisorsaService } from "./iidOlRisorsa/iidOlRisorsa.service";
import { IIDolRisorsaStore } from "./iidOlRisorsa/iidOlRisorsa.store";
import { IIDolRiferimentiUiQuery } from "./iidOlRiferimentiUi/iidOlRiferimentiUi.query";
import { IIDolRiferimentiUiService } from "./iidOlRiferimentiUi/iidOlRiferimentiUi.service";
import { IIDolRiferimentiUiStore } from "./iidOlRiferimentiUi/iidOlRiferimentiUi.store";
import { IIDMetaInterfaceStore } from "./iid-meta-interface/iid-meta-interface.store";
import { IIDMetaInterfaceQuery } from "./iid-meta-interface/iid-meta-interface.query";
import { IIDMetaInterfaceService } from "./iid-meta-interface/iid-meta-interface.service";
import { IIDepGisfoSupportService } from "./iidEPGisfoSupport/iidEPGisfoSupport.service";
import { IIDGrResourcesQuery } from "./iidGRResources/iidGRResources.query";
import { IIDGrResourcesStore } from "./iidGRResources/iidGRResources.store";
import { IIDGrResourcesService } from "./iidGRResources/iidGRResources.service";
import { IIDGrTasksQuery } from "./iidGRTasks/iidGRTasks.query";
import { IIDGrTasksStore } from "./iidGRTasks/iidGRTasks.store";
import { IIDGrTasksService } from "./iidGRTasks/iidGRTasks.service";
import { IIDGrResourcesMapQuery } from "./iidGRResourcesMap/iidGRResourcesMap.query";
import { IIDGrResourcesMapStore } from "./iidGRResourcesMap/iidGRResourcesMap.store";
import { IIDGrResourcesMapService } from "./iidGRResourcesMap/iidGRResourcesMap.service";
import { IIDolDeactivationStore } from "./iidOlDeactivation/iidOlDeactivation.store";
import { IIDolDeactivationQuery } from "./iidOlDeactivation/iidOlDeactivation.query";
import { IIDolDeactivationService } from "./iidOlDeactivation/iidOlDeactivation.service";
import { IIDolDeactivationValidationQuery } from "./iidOlDeactivationValidation/iidOlDeactivationValidation.query";
import { IIDolDeactivationValidationStore } from "./iidOlDeactivationValidation/iidOlDeactivationValidation.store";
import { IIDolDeactivationValidationService } from "./iidOlDeactivationValidation/iidOlDeactivationValidation.service";
import { IIDolDeactivationPhysicService } from "./iidOlDeactivationPhysic/iidOlDeactivationPhysic.service";
import { IIDolDeactivationPhysicQuery } from "./iidOlDeactivationPhysic/iidOlDeactivationPhysic.query";
import { IID_Generic_KoQuery } from "./iidGenericKo/genericKo.query";
import { IID_Generic_KoStore } from "./iidGenericKo/genericKo.store";
import { IID_Generic_KoService } from "./iidGenericKo/genericKo.service";
import { IIDolDeactivationPhysicStore } from "./iidOlDeactivationPhysic/iidOlDeactivationPhysic.store";
import { IIDMailGtwQuery } from "./iidMailGtw/iidMailGtw.query";
import { IIDMailGtwStore } from "./iidMailGtw/iidMailGtw.store";
import { IIDMailGtwService } from "./iidMailGtw/iidMailGtw.service";
import { IIDassuranceTicketQuery } from "./iidAssuranceTicket/iidAssuranceTicket.query";
import { IIDassuranceTicketStore } from "./iidAssuranceTicket/iidAssuranceTicket.store";
import { IIDassuranceTicketService } from "./iidAssuranceTicket/iidAssuranceTicket.service";
import { IID_Generic_OkQuery } from "./iidGenericOk/genericOk.query";
import { IID_Generic_OkStore } from "./iidGenericOk/genericOk.store";
import { IID_Generic_OkService } from "./iidGenericOk/genericOk.service";
import { IIDNetworkItemsQuery } from "./iidNetworkItems/iidNetworkItems.query";
import { IIDFaultManagementQuery } from "./iidFaultManagement/iidFaultManagement.query";
import { IIDNetworkItemsStore } from "./iidNetworkItems/iidNetworkItems.store";
import { IIDFaultManagementStore } from "./iidFaultManagement/iidFaultManagement.store";
import { IIDNetworkItemsService } from "./iidNetworkItems/iidNetworkItems.service";
import { IIDFaultManagementService } from "./iidFaultManagement/iidFaultManagement.service";
import { IID_Generic_AppointmentQuery } from "./iidGenericAppointment/genericAppointment.query";
import { IID_Generic_AppointmentStore } from "./iidGenericAppointment/genericAppointment.store";
import { IID_Generic_AppointmentService } from "./iidGenericAppointment/genericAppointment.service";
import { IIDolOloRequestOFQuery } from "./iidOlOloRequestOF/iidOlOloRequestOF.query";
import { IIDolOloRequestOFStore } from "./iidOlOloRequestOF/iidOlOloRequestOF.store";
import { IIDolOloRequestOFService } from "./iidOlOloRequestOF/iidOlOloRequestOF.service";
import { IIDolRisorsaPniQuery } from "./iidOlRisorsaPni/iidOlRisorsaPni.query";
import { IIDolRisorsaPniStore } from "./iidOlRisorsaPni/iidOlRisorsaPni.store";
import { IIDolRisorsaPniService } from "./iidOlRisorsaPni/iidOlRisorsaPni.service";
import { IIDolEspletatoOkOFQuery } from "./iidOlEspletatoOkOF/espletatoOkOF.query";
import { IIDolEspletatoOkOFStore } from "./iidOlEspletatoOkOF/espletatoOkOF.store";
import { IIDolEspletatoOkOFService } from "./iidOlEspletatoOkOF/espletatoOkOF.service";


export const interfaceDataQueryMap: Record<
  InterfaceNameValue,
  ProviderToken<
    AbstractInterfaceDataQuery<IBaseInterfaceData>
  >
> = {
  [InterfaceName.META_INTERFACE]: IIDMetaInterfaceQuery,
  [InterfaceName.OLO_REQUEST]: IIDolOloRequestQuery,
  [InterfaceName.ACTIVATION_REQUEST]: IIDolActivationRequestQuery,
  [InterfaceName.CLIENTE]: IIDolClienteQuery,
  [InterfaceName.CONTRATTO]: IIDolContrattoQuery,
  [InterfaceName.RIFERIMENTI]: IIDolRiferimentiQuery,
  [InterfaceName.CONDOMINIO]: IIDolCondominioQuery,
  [InterfaceName.SERVIZIO_AGGIUNTIVO]: IIDolServizioAggiuntivoQuery,
  [InterfaceName.APPARATO]: IIDolApparatoQuery,
  [InterfaceName.ASSETS]: IIDepAssetsQuery,
  [InterfaceName.CHECKLIST]: IIDolChecklistQuery,
  [InterfaceName.RICHIESTA_PERMESSO]: IIDEpRichiestaQuery,
  [InterfaceName.RISPOSTA]: IIDEpRispostaQuery,
  [InterfaceName.APPUNTAMENTO]: IIDolAppuntamentoQuery,
  [InterfaceName.ACQUISIZIONE_NEGATIVA]: IIDolAquisitiKoQuery,
  [InterfaceName.ACCETTAZIONE_NEGATIVA]: IIDolAccettatoKoQuery,
  [InterfaceName.ESPLETAMENTO_NEGATIVO]: IIDolEspletatoKoQuery,
  [InterfaceName.ESPLETAMENTO_POSITIVO]: IIDolEspletatoOkQuery,
  [InterfaceName.ANNULLATO]: IIDolAnnullatoQuery,
  [InterfaceName.OLO_COMMUNICATION]: IIDolCommunicationQuery,
  [InterfaceName.OLO_SOSPENSIONE]: IIDolSospensioneQuery,
  [InterfaceName.EP_TICKET]: IIDepTicketQuery,
  [InterfaceName.ORDINE_OLO]: IIDolSollecitoQuery,
  [InterfaceName.RISPOSTA_SOLLECITO]: IIDolRispostaSollecitoQuery,
  [InterfaceName.INSTALLAZIONE]: IIDolSupportoQuery,
  [InterfaceName.OLO_RISOLUZIONE]: IIDolRisoluzioneQuery,
  [InterfaceName.GENERIC_SUSPENSION]: IIDgenericSuspensionQuery,
  [InterfaceName.OLO_RISORSA]: IIDolRisorsaQuery,
  [InterfaceName.OLO_RIFERIMENTI_UI]: IIDolRiferimentiUiQuery,
  [InterfaceName.SUPPORT]: IIDepGisfoSupportQuery,
  [InterfaceName.GENERIC_KO]: IID_Generic_KoQuery,
  [InterfaceName.GR_RESOURCES]: IIDGrResourcesQuery,
  [InterfaceName.GR_TASKS]: IIDGrTasksQuery,
  [InterfaceName.GR_RESOURCES_MAP]: IIDGrResourcesMapQuery,
  [InterfaceName.OLO_DEACTIVATION]: IIDolDeactivationQuery,
  [InterfaceName.OLO_DEACTIVATION_VALIDATION]: IIDolDeactivationValidationQuery,
  [InterfaceName.OLO_DEACTIVATION_PHYSIC]: IIDolDeactivationPhysicQuery,
  [InterfaceName.MAIL_GTW_SOURCE]: IIDMailGtwQuery,
  [InterfaceName.ASSURANCE_TICKET]: IIDassuranceTicketQuery,
  [InterfaceName.GENERIC_OK]: IID_Generic_OkQuery,
  [InterfaceName.NETWORK_ITEMS]: IIDNetworkItemsQuery,
  [InterfaceName.FAULT_MANAGEMENT]: IIDFaultManagementQuery,
  [InterfaceName.GENERIC_APPOINTMENT]: IID_Generic_AppointmentQuery,
  [InterfaceName.OLO_REQUEST_OF]: IIDolOloRequestOFQuery,
  [InterfaceName.OLO_RISORSA_PNI]: IIDolRisorsaPniQuery,
  [InterfaceName.ESPLETAMENTO_POSITIVO_OF]: IIDolEspletatoOkOFQuery,
};

export const interfaceDataStoreMap: Record<
  InterfaceNameValue,
  ProviderToken<
    EntityStore<EntityState<IBaseInterfaceData, ID>>
  >
> = {
  [InterfaceName.META_INTERFACE]: IIDMetaInterfaceStore,
  [InterfaceName.OLO_REQUEST]: IIDolOloRequestStore,
  [InterfaceName.ACTIVATION_REQUEST]: IIDolActivationRequestStore,
  [InterfaceName.CLIENTE]: IIDolClienteStore,
  [InterfaceName.CONTRATTO]: IIDolContrattoStore,
  [InterfaceName.RIFERIMENTI]: IIDolRiferimentiStore,
  [InterfaceName.CONDOMINIO]: IIDolCondominioStore,
  [InterfaceName.SERVIZIO_AGGIUNTIVO]: IIDolServizioAggiuntivoStore,
  [InterfaceName.APPARATO]: IIDolApparatoStore,
  [InterfaceName.ASSETS]: IIDepAssetsStore,
  [InterfaceName.CHECKLIST]: IIDolChecklistStore,
  [InterfaceName.RICHIESTA_PERMESSO]: IIDEpRichiestaStore,
  [InterfaceName.RISPOSTA]: IIDEpRispostaStore,
  [InterfaceName.APPUNTAMENTO]: IIDolAppuntamentoStore,
  [InterfaceName.ACQUISIZIONE_NEGATIVA]: IIDolAquisitiKoStore,
  [InterfaceName.ACCETTAZIONE_NEGATIVA]: IIDolAccettatoKoStore,
  [InterfaceName.ESPLETAMENTO_NEGATIVO]: IIDolEspletatoKoStore,
  [InterfaceName.ESPLETAMENTO_POSITIVO]: IIDolEspletatoOkStore,
  [InterfaceName.ANNULLATO]: IIDolAnnullatoStore,
  [InterfaceName.OLO_COMMUNICATION]: IIDolCommunicationStore,
  [InterfaceName.OLO_SOSPENSIONE]: IIDolSospensioneStore,
  [InterfaceName.EP_TICKET]: IIDepTicketStore,
  [InterfaceName.ORDINE_OLO]: IIDolSollecitoStore,
  [InterfaceName.RISPOSTA_SOLLECITO]: IIDolRispostaSollecitoStore,
  [InterfaceName.INSTALLAZIONE]: IIDolSupportoStore,
  [InterfaceName.OLO_RISOLUZIONE]: IIDolRisoluzioneStore,
  [InterfaceName.GENERIC_SUSPENSION]: IIDgenericSuspensionStore,
  [InterfaceName.OLO_RISORSA]: IIDolRisorsaStore,
  [InterfaceName.OLO_RIFERIMENTI_UI]: IIDolRiferimentiUiStore,
  [InterfaceName.SUPPORT]: IIDepGisfoSupportStore,
  [InterfaceName.GENERIC_KO]: IID_Generic_KoStore,
  [InterfaceName.GR_RESOURCES]: IIDGrResourcesStore,
  [InterfaceName.GR_TASKS]: IIDGrTasksStore,
  [InterfaceName.GR_RESOURCES_MAP]: IIDGrResourcesMapStore,
  [InterfaceName.OLO_DEACTIVATION]: IIDolDeactivationStore,
  [InterfaceName.OLO_DEACTIVATION_VALIDATION]: IIDolDeactivationValidationStore,
  [InterfaceName.OLO_DEACTIVATION_PHYSIC]: IIDolDeactivationPhysicStore,
  [InterfaceName.MAIL_GTW_SOURCE]: IIDMailGtwStore,
  [InterfaceName.ASSURANCE_TICKET]: IIDassuranceTicketStore,
  [InterfaceName.GENERIC_OK]: IID_Generic_OkStore,
  [InterfaceName.NETWORK_ITEMS]: IIDNetworkItemsStore,
  [InterfaceName.FAULT_MANAGEMENT]: IIDFaultManagementStore,
  [InterfaceName.GENERIC_APPOINTMENT]: IID_Generic_AppointmentStore,
  [InterfaceName.OLO_REQUEST_OF]: IIDolOloRequestOFStore,
  [InterfaceName.OLO_RISORSA_PNI]: IIDolRisorsaPniStore,
  [InterfaceName.ESPLETAMENTO_POSITIVO_OF]: IIDolEspletatoOkOFStore,
};

export const interfaceDataServiceMap: Record<
  InterfaceNameValue,
  ProviderToken<
    CrudService<
      IBaseInterfaceData,
      EntityStore<EntityState<IBaseInterfaceData, ID>>,
      AbstractInterfaceDataQuery<IBaseInterfaceData>
    >
  >
> = {
  [InterfaceName.META_INTERFACE]: IIDMetaInterfaceService,
  [InterfaceName.OLO_REQUEST]: IIDolOloRequestService,
  [InterfaceName.ACTIVATION_REQUEST]: IIDolActivationRequestService,
  [InterfaceName.CLIENTE]: IIDolClienteService,
  [InterfaceName.CONTRATTO]: IIDolContrattoService,
  [InterfaceName.RIFERIMENTI]: IIDolRiferimentiService,
  [InterfaceName.CONDOMINIO]: IIDolCondominioService,
  [InterfaceName.SERVIZIO_AGGIUNTIVO]: IIDolServizioAggiuntivoService,
  [InterfaceName.APPARATO]: IIDolApparatoService,
  [InterfaceName.ASSETS]: IIDepAssetsService,
  [InterfaceName.CHECKLIST]: IIDolChecklistService,
  [InterfaceName.RICHIESTA_PERMESSO]: IIDEpRichiestaService,
  [InterfaceName.RISPOSTA]: IIDEpRispostaService,
  [InterfaceName.APPUNTAMENTO]: IIDolAppuntamentoService,
  [InterfaceName.ACQUISIZIONE_NEGATIVA]: IIDolAquisitiKoService,
  [InterfaceName.ACCETTAZIONE_NEGATIVA]: IIDolAccettatoKoService,
  [InterfaceName.ESPLETAMENTO_NEGATIVO]: IIDolEspletatoKoService,
  [InterfaceName.ESPLETAMENTO_POSITIVO]: IIDolEspletatoOkService,
  [InterfaceName.ANNULLATO]: IIDolAnnullatoService,
  [InterfaceName.OLO_COMMUNICATION]: IIDolCommunicationService,
  [InterfaceName.OLO_SOSPENSIONE]: IIDolSospensioneService,
  [InterfaceName.EP_TICKET]: IIDepTicketService,
  [InterfaceName.ORDINE_OLO]: IIDolSollecitoService,
  [InterfaceName.RISPOSTA_SOLLECITO]: IIDolRispostaSollecitoService,
  [InterfaceName.INSTALLAZIONE]: IIDolSupportoService,
  [InterfaceName.OLO_RISOLUZIONE]: IIDolRisoluzioneService,
  [InterfaceName.GENERIC_SUSPENSION]: IIDgenericSuspensionService,
  [InterfaceName.OLO_RISORSA]: IIDolRisorsaService,
  [InterfaceName.OLO_RIFERIMENTI_UI]: IIDolRiferimentiUiService,
  [InterfaceName.SUPPORT]: IIDepGisfoSupportService,
  [InterfaceName.GENERIC_KO]: IID_Generic_KoService,
  [InterfaceName.GR_RESOURCES]: IIDGrResourcesService,
  [InterfaceName.GR_TASKS]: IIDGrTasksService,
  [InterfaceName.GR_RESOURCES_MAP]: IIDGrResourcesMapService,
  [InterfaceName.OLO_DEACTIVATION]: IIDolDeactivationService,
  [InterfaceName.OLO_DEACTIVATION_VALIDATION]: IIDolDeactivationValidationService,
  [InterfaceName.OLO_DEACTIVATION_PHYSIC]: IIDolDeactivationPhysicService,
  [InterfaceName.MAIL_GTW_SOURCE]: IIDMailGtwService,
  [InterfaceName.ASSURANCE_TICKET]: IIDassuranceTicketService,
  [InterfaceName.GENERIC_OK]: IID_Generic_OkService,
  [InterfaceName.NETWORK_ITEMS]: IIDNetworkItemsService,
  [InterfaceName.FAULT_MANAGEMENT]: IIDFaultManagementService,
  [InterfaceName.GENERIC_APPOINTMENT]: IID_Generic_AppointmentService,
  [InterfaceName.OLO_REQUEST_OF]: IIDolOloRequestOFService,
  [InterfaceName.OLO_RISORSA_PNI]: IIDolRisorsaPniService,
  [InterfaceName.ESPLETAMENTO_POSITIVO_OF]: IIDolEspletatoOkOFService,
};
